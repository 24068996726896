import './App.css';
import React from 'react';
import MainPage from './Components/MainPage'
import { HashRouter } from 'react-router-dom/cjs/react-router-dom.min';
function App() {
  return (
    <div className="App">
      <HashRouter>
        <MainPage />
      </HashRouter>
    </div>
  );
}

export default App;
