import React from 'react'

function Industries() {
    return (
        <div>
            Hello
        </div>
    )
}

export default Industries
